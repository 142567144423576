import React from 'react'
import { ClientLazy } from '~/components'

export default function PdfClientBrowser({
  document,
}: {
  document: React.ReactElement
}) {
  return (
    <ClientLazy
      component={() => import('./LazyPdfViewer')}
      document={document}
    />
  )
}
