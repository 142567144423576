import { useEffect, useState } from 'react'
import { useControlField, type FormContextValue } from 'remix-validated-form'
import { FieldContainer } from '~/components/admin'
import { Input } from '~/components/ui'
import { useDebounce } from '~/hooks'

export default function PdfPreparedForField({
  formId,
  name,
  formContext,
  initialValue,
}: {
  formId: string
  name: string
  initialValue?: string
  formContext?: FormContextValue
}) {
  const [preparedForValue, setPreparedForValue] = useState<string>(
    initialValue || ''
  )
  const debouncedPreparedForValue = useDebounce(preparedForValue, 1000)
  const [__, setPreparedFor] = useControlField<string>(name, formId)

  useEffect(() => {
    setPreparedFor(debouncedPreparedForValue)
  }, [debouncedPreparedForValue, setPreparedFor])

  return (
    <FieldContainer
      label="Recipient Name"
      id="preparedFor"
      className="col-span-12">
      <Input
        id="preparedFor"
        disabled={formContext?.isSubmitting}
        name="options.preparedFor"
        value={preparedForValue}
        onChange={(e) => setPreparedForValue(e.target.value)}
      />
    </FieldContainer>
  )
}
