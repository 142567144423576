import { Font } from '@react-pdf/renderer'
import { createTw } from 'react-pdf-tailwind'

Font.registerHyphenationCallback((word) => [word])
Font.register({
  family: 'Montserrat',
  src: 'https://storage.googleapis.com/resimplifi-associate/fonts/Montserrat/Montserrat-Medium.ttf',
})
Font.register({
  family: 'Source Sans 3',
  fonts: [
    {
      src: 'https://storage.googleapis.com/resimplifi-associate/fonts/SourceSans3/SourceSans3-Regular.ttf',
      fontWeight: 'normal',
      fontStyle: 'normal',
    },
    {
      src: 'https://storage.googleapis.com/resimplifi-associate/fonts/SourceSans3/SourceSans3-Italic.ttf',
      fontWeight: 'normal',
      fontStyle: 'italic',
    },
    {
      src: 'https://storage.googleapis.com/resimplifi-associate/fonts/SourceSans3/SourceSans3-Medium.ttf',
      fontWeight: 'medium',
    },
    {
      src: 'https://storage.googleapis.com/resimplifi-associate/fonts/SourceSans3/SourceSans3-SemiBold.ttf',
      fontWeight: 'semibold',
    },
    {
      src: 'https://storage.googleapis.com/resimplifi-associate/fonts/SourceSans3/SourceSans3-Bold.ttf',
      fontWeight: 'bold',
    },
  ],
})

export const tw = createTw({
  theme: {
    fontFamily: {
      sans: ['Source Sans 3'],
      heading: ['Montserrat'],
      // sans: ['Helvetica'],
      // heading: ['Helvetica'],
    },
    extend: {
      colors: {
        primary: '#7c3aed',
        muted: '#6b7280',
        'card-foreground': '#030712',
        border: '#e5e7eb',
      },
      borderRadius: {
        lg: '0.5rem',
        md: '6px',
        sm: '3px',
      },
    },
  },
})
