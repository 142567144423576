import { Image, Link, Text, View } from '@react-pdf/renderer'
import { tw } from '~/components/pdfs'
import type { Company, User } from '~/models'
import { cn, fmtAddress, fmtPhoneNumber, transformImageUrl } from '~/utils'

export default function PdfBrokerProfile({
  orientation,
  user,
  company,
}: {
  orientation: 'portrait' | 'landscape'
  user: User
  company?: Company
}) {
  // if (!pdfOptions.showBrokerProfile) return null
  return (
    <View
      style={tw(
        cn(
          'w-1/3 bg-gray-100 p-8',
          orientation === 'portrait' ? 'h-[792px]' : 'h-[612px]'
        )
      )}>
      <View style={tw('flex flex-row justify-center')}>
        {user.meta?.photo ? (
          <Image
            style={tw('w-36 rounded-full')}
            src={transformImageUrl(user.meta?.photo?.url)}
          />
        ) : (
          <View
            style={tw(
              'flex h-36 w-36 flex-row items-center justify-center rounded-full bg-white'
            )}>
            <Text style={tw('mt-2 text-2xl font-medium')}>
              {[user.firstName.slice(0, 1), user.lastName.slice(0, 1)]
                .join('')
                .toUpperCase()}
            </Text>
          </View>
        )}
      </View>
      <View style={tw('mt-8 flex flex-col gap-1')}>
        <View style={tw('flex flex-row')}>
          <Text style={tw('text-base font-bold')}>
            {`${user.firstName} ${user.lastName}`}
          </Text>
          {user.meta?.memberships && (
            <Text style={tw('ml-2 text-base text-muted-foreground')}>
              {user.meta.memberships.split(/\s+|,\s+|\|/).join(', ')}
            </Text>
          )}
        </View>
        {user.meta?.title && (
          <Text style={tw('text-sm')}>{user.meta.title}</Text>
        )}
        {user.email && (
          <View style={tw('mt-1 flex flex-row')}>
            <Text style={tw('w-4 text-sm')}>E:</Text>
            <Link
              style={tw('text-sm text-primary no-underline')}
              href={`mailto:${user.email}`}>
              {user.email}
            </Link>
          </View>
        )}
        {user.meta?.office && (
          <View style={tw('mt-1 flex flex-row')}>
            <Text style={tw('w-4 text-sm')}>O:</Text>
            <Link
              style={tw('text-sm text-primary no-underline')}
              href={`tel:${user.meta?.office}`}>
              {fmtPhoneNumber(user.meta.office)}
            </Link>
          </View>
        )}
        {user.meta?.mobile && (
          <View style={tw('mt-1 flex flex-row')}>
            <Text style={tw('w-4 text-sm')}>M:</Text>
            <Link
              style={tw('text-sm text-primary no-underline')}
              href={`tel:${user.meta?.mobile}`}>
              {fmtPhoneNumber(user.meta.mobile)}
            </Link>
          </View>
        )}
      </View>

      {company && (
        <View style={tw('mt-8 border-t border-gray-300 pt-8')}>
          <View style={tw('flex flex-row justify-center gap-4')}>
            {company.meta.logo && (
              <Image
                style={tw('h-36')}
                src={transformImageUrl(company.meta.logo.url)}
              />
            )}
          </View>
          <Text style={tw('mt-2 font-sans text-base font-bold')}>
            {company.name}
          </Text>
          {company.meta.address && (
            <Text style={tw('text-base')}>{fmtAddress(company.meta)}</Text>
          )}
        </View>
      )}
    </View>
  )
}
