import { useEffect, useState } from 'react'
import { useControlField, type FormContextValue } from 'remix-validated-form'
import { FieldContainer } from '~/components/admin'
import { Textarea } from '~/components/ui'
import { useDebounce } from '~/hooks'

export default function PdfNotesField({
  formId,
  name,
  initialValue,
  formContext,
}: {
  formId: string
  name: string
  initialValue?: string
  formContext?: FormContextValue
}) {
  const [notesValue, setNotesValue] = useState<string>(initialValue || '')
  const debouncedNotesValue = useDebounce(notesValue, 1000)
  const [notes, setNotes] = useControlField<string>(name, formId)

  useEffect(() => {
    if (debouncedNotesValue.trim().length === 0) {
      setNotes('')
    } else {
      const lines = debouncedNotesValue.split('\n')
      const html = lines
        .filter((v) => v)
        .map((line) => `<p>${line}</p>`)
        .join('')
      setNotes(html)
    }
  }, [debouncedNotesValue, setNotes])

  return (
    <FieldContainer label="Notes" id="notes" className="col-span-12">
      <input type="hidden" name="options.notes" value={notes} />
      <Textarea
        id="notes"
        rows={10}
        value={notesValue}
        disabled={formContext?.isSubmitting}
        onChange={(e) => setNotesValue(e.target.value)}
      />
    </FieldContainer>
  )
}
