import { Text } from '@react-pdf/renderer'
import React from 'react'
import { tw } from '~/components/pdfs'
import { cn } from '~/utils'

export default function PdfBadge({
  className,
  children,
}: {
  className?: string
  children: React.ReactNode
}) {
  return (
    <Text
      style={tw(
        cn(
          className,
          'rounded-sm pt-px px-2 leading-none font-sans text-[12px]'
        )
      )}>
      {children}
    </Text>
  )
}
