import { Text } from '@react-pdf/renderer'
import { format } from 'date-fns'
import { tw } from '~/components/pdfs'

export default function PdfPreparedFor({
  preparedFor,
}: {
  preparedFor?: string
}) {
  if (!preparedFor) {
    return (
      <Text style={tw('text-base font-sans text-muted')}>
        Prepared on {format(new Date(), 'PPP')}
      </Text>
    )
  }
  return (
    <Text style={tw('text-base font-sans text-muted')}>
      Prepared for {preparedFor} on {format(new Date(), 'PPP')}
    </Text>
  )
}
