import { Html } from 'react-pdf-html'
import { tw } from '~/components/pdfs'

export default function PdfNotes({ notes }: { notes?: string }) {
  if (!notes) return null
  return (
    <Html
      style={tw('mt-4 text-base font-sans leading-normal')}
      stylesheet={{
        p: { margin: '0 0 10px' },
        li: { margin: 0 },
        ul: { margin: 0 },
        'ul > ul': { margin: 0 },
      }}>
      {notes}
    </Html>
  )
}
